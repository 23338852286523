import { BaseUser } from '@/types/users.types';
import { LogicalBotAssignee } from '@/features/om27/types/edit-bot.types';
import invariant from 'ts-invariant';
import { randInt } from '@/utils/components';

export function convertAssigneeToUser(assignee: LogicalBotAssignee): BaseUser {
  // users generated outside of our system likely have `null` mudId
  // but our User components require it for uniqueness checks
  const mudId = assignee.humanUserMudId || assignee.id;
  return {
    mudId: mudId ? mudId + '' : '',
    email: '',
    userId: assignee.id,
    fullName: assignee.humanUser,
  };
}

export function convertUserToAssignee(user: BaseUser): LogicalBotAssignee {
  invariant(user.fullName, 'User must have a fullName to convert to Assignee');
  return {
    humanUser: user.fullName,
    humanUserMudId: user.mudId,
    id: user.userId || randInt() * -1,
  };
}
