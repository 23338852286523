import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import {
  LogicalBotAssignee,
  LogicalBotCategory,
  EditLogicalBotResponse,
  UpdateLogicalBotRequestBody,
  EditLogicalBotHistory,
} from '@/features/om27/types/edit-bot.types';

export function getLogicalBot(logicalBotID: number): AxiosPromise<EditLogicalBotResponse> {
  return axios.get(URLs.RpaOpsLogicalBot, {
    params: {
      logicalBotID,
    },
  });
}

export function updateLogicalBot(
  logicalBotID: number,
  data: UpdateLogicalBotRequestBody,
): AxiosPromise<void> {
  return axios.post(URLs.RpaOpsLogicalBot, data, {
    params: {
      logicalBotID,
    },
  });
}

export function getLogicalBotCategories(logicalBotId: number): AxiosPromise<LogicalBotCategory[]> {
  return axios.get(URLs.RpaOpsLogicalBotCategories, {
    params: {
      logicalBotId,
    },
  });
}

export function getLogicalBotAssignees(logicalBotId: number): AxiosPromise<LogicalBotAssignee[]> {
  return axios.get(URLs.RpaOpsLogicalBotAssignees, {
    params: {
      logicalBotId,
    },
  });
}

export function getLogicalBotEditHistory(logicalBotId: number): AxiosPromise<EditLogicalBotHistory[]> {
  return axios.get(URLs.RpaOpsEditLogicalBotHistory, {
    params: {
      logicalBotId,
    },
  });
}

export function createLogicalBotCategory(
  logicalBotId: number,
  logicalBotCategory: LogicalBotCategory,
): AxiosPromise<void> {
  return axios.post(URLs.RpaOpsLogicalBotCategories, logicalBotCategory, {
    params: { logicalBotId },
  });
}

export function createLogicalBotAssignee(
  logicalBotId: number,
  logicalBotAssignee: LogicalBotAssignee,
): AxiosPromise<void> {
  return axios.post(URLs.RpaOpsLogicalBotAssignees, logicalBotAssignee, {
    params: { logicalBotId },
  });
}

export function deleteLogicalBotCategory(
  logicalBotId: number,
  categoryId: number,
): AxiosPromise<void> {
  return axios.delete(URLs.RpaOpsLogicalBotCategory, {
    params: {
      logicalBotId,
      categoryId,
    },
  });
}

export function deleteLogicalBotAssignee(
  logicalBotId: number,
  assigneeId: number,
): AxiosPromise<void> {
  return axios.delete(URLs.RpaOpsLogicalBotAssignee, {
    params: {
      logicalBotId,
      assigneeId,
    },
  });
}
