
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import { RawLocation } from 'vue-router';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import {
  LogicalBotAssignee,
  LogicalBotCategory,
  EditLogicalBotResponse,
  EditBotFormObjectModel,
} from '@/features/om27/types/edit-bot.types';
import { RouteNames } from '@/constants';
import { convertAssigneeToUser } from '@/features/om27/utils/users';
import GButton from '@/components/gsk-components/GskButton.vue';
import { FormField, SelectOption } from '@/components/form/form.types';
import { RpaOpsModule } from '@/features/om27/store/om27.module';

@Component({
  components: {
    ValidatedForm,
    ValidationObserver,
    GButton,
  },
})
export default class EditBotForm extends Vue {
  @Prop({ type: Object, required: true }) logicalBotData!: EditLogicalBotResponse;
  @Prop({ type: Array, required: true }) categories!: LogicalBotCategory[];
  @Prop({ type: Array, required: true }) assignees!: LogicalBotAssignee[];
  @Prop({ type: Boolean }) loading!: boolean;

  editBotHistoryRoute: RawLocation = {
    name: RouteNames.RpaOpsEditBotHistory,
  };

  formModel: EditBotFormObjectModel = {
    botName: {
      sequence: 1,
      field: {
        key: 'botName',
        type: 'text',
        label: 'Bot Name',
        value: this.logicalBotData.logicalBot.botName,
        required: true,
        validation: {
          rules: {
            required: true,
          },
        },
      },
    },
    botDisabled: {
      sequence: 2,
      field: {
        key: 'botDisabled',
        type: 'checkbox-single',
        label: 'Bot is disabled',
        value: this.logicalBotData.logicalBot.botDisabled,
      },
    },
    botDesignFolder: {
      sequence: 3,
      field: {
        key: 'botDesignFolder',
        type: 'text',
        label: 'Design/Folder Name',
        value: this.logicalBotData.logicalBot.botDesignFolder,
      },
    },
    botAdditionalInfo: {
      sequence: 4,
      field: {
        key: 'botAdditionalInfo',
        type: 'text',
        label: 'Additional Information',
        value: this.logicalBotData.logicalBot.botAdditionalInfo ?? '',
      },
    },
    displayOrder: {
      sequence: 5,
      field: {
        key: 'displayOrder',
        type: 'number',
        label: 'Order',
        value: this.logicalBotData.logicalBot.displayOrder,
      },
    },
    scheduleDescription: {
      sequence: 6,
      field: {
        key: 'scheduleDescription',
        type: 'long-text',
        label: 'Schedule',
        value: this.logicalBotData.logicalBot.scheduleDescription ?? '',
      },
    },
    lenientAutoSignoffFlag: {
      sequence: 7,
      field: {
        key: 'lenientAutoSignoffFlag',
        type: 'checkbox-single',
        label: 'Lenient Sign Off',
        value: !!this.logicalBotData.logicalBot.lenientAutoSignoffFlag,
        attrs: {
          disabled: this.logicalBotData.logicalBot.simpleAutoSignoffBotId !== null,
        },
      },
    },
    lenientApprover: {
      sequence: 8,
      field: {
        key: 'lenientApprover',
        type: 'long-text',
        label: 'Lenient Approver and Notes',
        value: this.logicalBotData.logicalBot.lenientApprover?.trim() || '',
        attrs: {
          disabled: !this.logicalBotData.logicalBot.lenientAutoSignoffFlag,
          maxLength: '1000',
        },
        validation: {
          rules: 'max:1000',
        },
      },
    },
    simpleAutoSignoffBotId: {
      sequence: 9,
      field: {
        key: 'simpleAutoSignoffBotId',
        type: 'select',
        label: 'Simple Auto Sign Off Bot Id',
        value: String(this.logicalBotData.logicalBot.simpleAutoSignoffBotId),
        attrs: {
          disabled: !!this.logicalBotData.logicalBot.lenientAutoSignoffFlag,
          hidePlaceholder: true,
        },
        options: this.simpleSignOffBotList,
      },
    },
    scheduleGroupId: {
      sequence: 10,
      field: {
        key: 'scheduleGroupId',
        type: 'select',
        label: 'Auto Sign Off Schedule',
        value: String(this.logicalBotData.logicalBot.scheduleGroupId) || '',
        options: this.logicalBotData.scheduleGroupIdValues.map(item => ({
          value: item.scheduleId,
          label: item.description,
        })),
        showClear: true,
      },
    },
    schedTimeZone: {
      sequence: 11,
      field: {
        key: 'schedTimeZone',
        type: 'select',
        label: 'Bot Time Zone',
        value: this.logicalBotData.logicalBot.schedTimeZone || '',
        options: this.logicalBotData.timeZoneValues,
        showClear: true,
      },
    },
    schedStartTime: {
      sequence: 12,
      field: {
        key: 'schedStartTime',
        type: 'text',
        label: 'Bot Start Time (HH:MM)',
        value: this.logicalBotData.logicalBot.schedStartTime ?? '',
        validation: {
          rules: {
            regex: /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/,
          },
          customMessages: {
            regex: 'Bot start time must be a valid time string HH:MM',
          },
        },
      },
    },
    schedEndTime: {
      sequence: 13,
      field: {
        key: 'schedEndTime',
        type: 'text',
        label: 'Bot End Time (HH:MM)',
        value: this.logicalBotData.logicalBot.schedEndTime ?? '',
        validation: {
          rules: {
            regex: /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/,
          },
          customMessages: {
            regex: 'Bot end time must be a valid time string HH:MM',
          },
        },
      },
    },
    assignedUsers: {
      sequence: 14,
      field: {
        key: 'assignedUsers',
        leadingHeader: 'Assigned Users',
        type: 'people-picker-table',
        value: [],
        label: 'Assigned Users',
        tableFieldKey: 'fullName',
        tableLabel: 'Current Assigned Users',
      },
    },
    botTriggerType: {
      sequence: 15,
      field: {
        key: 'botTriggerType',
        type: 'text',
        label: 'Trigger Type',
        value: this.logicalBotData.logicalBot.botTriggerType ?? '',
      },
    },
    assignmentGroup: {
      sequence: 16,
      field: {
        key: 'assignmentGroup',
        label: 'Assignment Group',
        type: 'select',
        value: this.logicalBotData.logicalBot.assignmentGroup,
        options: this.logicalBotData.assignmentGroups,
        required: true,
        validation: {
          rules: {
            required: true,
          },
        },
      },
    },
    supportTeam: {
      sequence: 17,
      field: {
        key: 'supportTeam',
        type: 'select',
        options: this.logicalBotData.supportTeams,
        value: this.logicalBotData.logicalBot.supportTeam ?? '',
        label: 'Support Team',
      },
    },
    soxValue: {
      sequence: 18,
      field: {
        key: 'soxValue',
        label: 'SOX Value',
        type: 'radio',
        value: this.logicalBotData.logicalBot.soxValue,
        options: this.logicalBotData.soxValues,
      },
    },
    soxReason: {
      sequence: 19,
      field: {
        key: 'soxReason',
        label: 'SOX Reason',
        type: 'select',
        value: String(this.logicalBotData.logicalBot.soxReason),
        options: this.soxReasonValues,
        attrs: {
          hidePlaceholder: true,
        },
      },
    },
    soxDocumentReference: {
      sequence: 20,
      field: {
        key: 'soxDocumentReference',
        type: 'text',
        label: 'SOX Document Reference',
        value: this.logicalBotData.logicalBot.soxDocumentReference ?? '',
      },
    },
    soxDescription: {
      sequence: 21,
      field: {
        key: 'soxDescription',
        type: 'long-text',
        label: 'SOX Description',
        value: this.logicalBotData.logicalBot.soxDescription,
      },
    },
    soxHumanApprover: {
      sequence: 22,
      field: {
        key: 'soxHumanApprover',
        type: 'people-picker',
        label: 'SOX Approver',
        value: this.logicalBotData.logicalBot.soxHumanApprover
          ? [{
              firstName: this.logicalBotData.logicalBot.soxHumanApprover,
              mudId: this.logicalBotData.logicalBot.soxHumanApprover,
              email: '',
            }]
          : [],
        attrs: { singleSelect: true, 'max-values': '1' },
      },
    },
    categories: {
      sequence: 23,
      field: {
        key: 'categories',
        label: 'unused',
        leadingHeader: 'Categories',
        type: 'rpa-categories',
        value: [],
      },
    },
    evidenceTemplate: {
      sequence: 24,
      field: {
        key: 'evidenceTemplate',
        type: 'long-text',
        label: 'Evidence Template',
        value: this.logicalBotData.logicalBot.evidenceTemplate ?? '',
        attrs: {
          placeholder: 'Enter template here',
        },
      },
    },
    updateNotes: {
      sequence: 25,
      field: {
        key: 'updateNotes',
        type: 'long-text',
        label: 'Update Notes',
        value: '',
        attrs: {
          placeholder: 'Why are you making these changes?',
        },
      },
    },
  };

  onInputValidatedForm(form: EditBotFormObjectModel, field: FormField, value: FormField['value']) {
    if (field.key === 'simpleAutoSignoffBotId') {
      const { attrs: lenientFlagAttrs } = this.formModel.lenientAutoSignoffFlag.field;
      this.$set(this.formModel.lenientAutoSignoffFlag.field, 'attrs', {
        ...lenientFlagAttrs,
        disabled: !!Number(value),
      });
    } else
    if (field.key === 'lenientAutoSignoffFlag') {
      const { attrs: simpleSignOffFlagAttrs } = this.formModel.simpleAutoSignoffBotId.field;
      this.$set(this.formModel.simpleAutoSignoffBotId.field, 'attrs', {
        ...simpleSignOffFlagAttrs,
        disabled: !!value
      });

      const { attrs: lenientApproverAttrs } = this.formModel.lenientApprover.field;
      this.$set(this.formModel.lenientApprover.field, 'attrs', {
        ...lenientApproverAttrs,
        disabled: !value,
      });
    }
  }

  get soxReasonValues(): SelectOption[] {
    const values = this.logicalBotData.soxReasonValues.map(label => ({
      value: label,
      label,
    }));
    return [{ value: 'null', label: 'Select a sox reason' }, ...values];
  }

  get simpleSignOffBotList(): SelectOption[] {
    const botList = this.logicalBotData.simpleAutoSignoffBotValues.map((bot) => {
      const botValues = Object.values({ ...bot, botId: '' });
      return {
        value: String(bot.botId),
        label: botValues.filter(item => !!item).join(', '),
      };
    });
    return [{ value: 'null', label: 'Select a bot' }, ...botList];
  }

  get form() {
    return orderBy(values(this.formModel), 'sequence').map(f => f.field);
  }
  set form(v: { key: keyof EditBotFormObjectModel; value: any }[]) {
    for (const { key, value } of v) {
      this.$set(this.formModel[key].field, 'value', value);
    }
  }

  created() {
    this.formModel.categories.field.value = this.categories.map(cat => ({
      ...cat,
      isNewCategory: false,
    }));
    this.formModel.assignedUsers.field.value = this.assignees.map(convertAssigneeToUser);
  }

  handleForm() {
    this.$emit('submit', this.formModel);
  }

  onClickViewEditHistory() {
    const { simpleAutoSignoffBotValues } = this.logicalBotData;
    RpaOpsModule.setEditHistoryLogicalBotLinkedBots(simpleAutoSignoffBotValues);
  }
}
