
import { Component, Vue } from 'vue-property-decorator';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import FullScreenForm from '@/components/FullScreenForm.vue';
import { RawLocation } from 'vue-router';
import { RouteNames } from '@/constants';
import { RpaOpsModule } from '@/features/om27/store/om27.module';
import { LogicalBot, UserPreferences } from '@/features/om27/types/om27.types';
import { Promised } from 'vue-promised';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import { openErrorSnackbar, openSuccessSnackbar } from '@/utils/components';
import {
  createLogicalBotAssignee,
  createLogicalBotCategory,
  deleteLogicalBotAssignee,
  deleteLogicalBotCategory,
  getLogicalBot,
  getLogicalBotAssignees,
  getLogicalBotCategories,
  updateLogicalBot,
} from '@/features/om27/api/edit-bot.api';
import {
  EditBotFormObjectModel,
  EditLogicalBotResponse,
  EditLogicalBot,
  LogicalBotAssignee,
  LogicalBotCategory,
} from '@/features/om27/types/edit-bot.types';
import EditBotForm from '@/features/om27/components/EditBotForm.vue';
import { listDiff } from '@/utils/list';
import { convertUserToAssignee } from '@/features/om27/utils/users';
import { convertBotFormToRequestData } from '@/features/om27/utils/edit-bot';
import { AxiosError } from 'axios';

@Component({
  components: {
    Grid,
    GridCell,
    FullScreenForm,
    Promised,
    EditBotForm,
    GDialog,
  },
})
export default class EditBot extends Vue {
  closeRoute: RawLocation = {
    name: RouteNames.Om27,
  };

  get timezone(): UserPreferences['preferred_timezone'] {
    return RpaOpsModule.userPreferences.preferred_timezone;
  }
  get logicalBotId(): number {
    return parseInt(this.$route.params.logicalBotId, 10);
  }

  get logicalBot(): LogicalBot | undefined {
    return RpaOpsModule.logicalBots.find(bot => bot.logicalBotId === this.logicalBotId);
  }

  loadBotDataPromiseList(): Promise<any>[] {
    return [
      getLogicalBot(this.logicalBotId).then((res) => {
        this.initialEditLogicalBotData = res.data.logicalBot;
        return res.data;
      }),
      getLogicalBotCategories(this.logicalBotId).then(({ data }) => {
        this.initialCategories = Object.freeze(data);
        return data;
      }),
      getLogicalBotAssignees(this.logicalBotId).then(({ data }) => {
        this.initialAssignees = Object.freeze(data);
        return data;
      }),
    ];
  }

  formLoading = false;
  handleForm(data: EditBotFormObjectModel): void {
    this.formLoading = true;
    const categoryDiff = listDiff(
      this.initialCategories,
      data.categories.field.value,
      cat => cat.id,
    );

    // diff initial assignees and data categories
    const users = data.assignedUsers.field.value;
    const assignees = users.map(convertUserToAssignee);
    const assigneeDiff = listDiff(this.initialAssignees, assignees, assignee => assignee.id);

    const categoryDeletes = categoryDiff.removed.map(category =>
      deleteLogicalBotCategory(this.logicalBotId, category.id),
    );
    const assigneeDeletes = assigneeDiff.removed.map(assignee =>
      deleteLogicalBotAssignee(this.logicalBotId, assignee.id),
    );

    const categoryCreates = categoryDiff.added.map(category =>
      createLogicalBotCategory(this.logicalBotId, category),
    );

    const assigneeCreates = assigneeDiff.added.map(assignee =>
      createLogicalBotAssignee(this.logicalBotId, assignee),
    );

    const botUpdate = updateLogicalBot(
      this.logicalBotId,
      convertBotFormToRequestData(data, this.initialEditLogicalBotData)
    );

    Promise.all([
      ...categoryDeletes,
      ...assigneeDeletes,
      ...categoryCreates,
      ...assigneeCreates,
      botUpdate,
    ])
      .then(() => {
        openSuccessSnackbar.call(this, 'Bot successfully updated.');
      })
      .catch(e => {
        openErrorSnackbar.call(this, this.errorMessage(e));
      })
      .finally(async () => {
        Promise.all(this.loadBotDataPromiseList());
        this.formLoading = false;
      });
  }

  errorMessage(e: AxiosError | Error) {
    if ('response' in e) {
      return e.response?.data?.message || e;
    }
    return e;
  }

  initialCategories: readonly LogicalBotCategory[] = [];
  initialAssignees: readonly LogicalBotAssignee[] = [];
  initialEditLogicalBotData: EditLogicalBot | null = null;
  promise: Promise<
    (EditLogicalBotResponse | LogicalBotCategory[] | LogicalBotAssignee[])[]
  > | null = null;
  async created(): Promise<void> {
    this.promise = Promise.all(this.loadBotDataPromiseList());
  }
}
